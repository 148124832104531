$( document ).ready(function() {

//*------------------------------------*\
//      variables
//*------------------------------------*/
// console.log('carouselObj.length',carouselObj.length);
    var carouselObj = window.carouselObj;

    if (typeof(carouselObj) == "undefined") {
        var carouselObj = {};
        $('.section2').html('');
    } else if (carouselObj.length < 10) {
        $('.testimony-nav').hide();
    }

    var carouselSize = carouselObj.length,
    cards = carouselSize,
    cardWidth = 277,
    fadeOffset = 95,
    rowsToMove = 3,
    caroWidth = cardWidth * (Math.ceil(cards/3) > 2 ? Math.ceil(cards/3) : 3),
    justasktemplate = '<span class="span justask js-justask moveIn">',
    $nameChangeMS = 1500,
    $unitsSoldMS = 2500,
    shuffledNames = shuffle(),
    centerTimer = 0,
    counter = 0,
    iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform),

    // ### TO INCREASE HOW MANY UNITS SOLD ADD TO THIS VARIABLE or use negative number to reduce units sold ### \\
    increaseUnitsSold = 0;

//*------------------------------------*\
//      PAGE setup
//*------------------------------------*/

    if(/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) $('.section').addClass('safari');

    // setup carousel
    fillCarousel();

    // set size and pos of carousel
    $('.slide-wrapper').css("width","" + caroWidth + "px").css("left",fadeOffset + "px");

    // set names to change
    changeName();

    // set units sold
    calcUnitsSold();

    // setup images for video cards
    setVideoImages();

    // if ios device then trigger graphs to raise the bars
    // if (iOS) {
    if (iOS) {
        $('.graph-container1').addClass('is-active');
        if (!$('.js-graph1').hasClass('is-active')) {
            $('.js-graph1').addClass('is-active');
            increaseNumber($('.js-graph1').text(),1);
        }
        $('.graph-container2').addClass('is-active');
        if (!$('.js-graph2').hasClass('is-active')) {
            $('.js-graph2').addClass('is-active');
            increaseNumber($('.js-graph2').text(),2);
        }
        $('.graph-container3').addClass('is-active');
        if (!$('.js-graph3').hasClass('is-active')) {
            $('.js-graph3').addClass('is-active');
            increaseNumber($('.js-graph3').text(),3);
        }
        $('.graph-container4').addClass('is-active');
        if (!$('.js-graph4').hasClass('is-active')) {
            $('.js-graph4').addClass('is-active');
            increaseNumber($('.js-graph4').text(),4);
        }
    }

    
//*------------------------------------*\
//      listeners
//*------------------------------------*/

    $('.js-carousel__nav').on('click', function(){
        moveCarousel($(this));
    });

    try {
        window.onscroll = function(){
            scrolled();
        };
    } catch (e) {
        console.log(e);
    }

    try {
        $(window).scroll(function(){
            scrolled();
        });
    } catch (e) {
        console.log(e);
    }

    $('.js-play-video').on('click', function(){
        centerTimer = 0;
        $('.videoScreen').addClass('is-active');
        var mediaid = $(this).data('mediaid');
        var imagepath = $(this).data('image');
        jwplayer('video1').setup({
            'mediaid' : mediaid,
            'image': imagepath,
            'autostart': true,
            'sources': [{
                'file': 'https://content.jwplatform.com/videos/' + mediaid + '-G7g7vhiJ.mp4'
              },{
                'file': 'https://content.jwplatform.com/manifests/' + mediaid + '.m3u8'
              },{
                'file': 'https://content.jwplatform.com/videos/' + mediaid + '.webm'
              }]
        });
        setTimeout(() => {
            placeInCenter('videoIDwrapper');
        }, 100);
    });

    $('.js-quote-card').on('click', function(){
        centerTimer = 0;
        $('.quoteScreen').addClass('is-active');
        var quote = $(this).find('.full-quote').text();
        var quoteName = $(this).find('.quote-name').text();
        var template = '<p class="p quote-title">' + quote + '</p><p class="p quote-name">' + quoteName + '</p>';
        $('#quote1').html(template);
        setTimeout(() => {
            placeInCenter("quote1");
        }, 100);
    });

    $('.js-result-card').on('click', function(){
        centerTimer = 0;
        $('.resultScreen').addClass('is-active');
        var result = $(this).find('.full-result').html();
        var resultImg = $(this).find('.result-img')[0].getAttribute('src');
        var resultName = $(this).find('.result-name').text();
        var resultLocation = $(this).find('.result-location').text();
        var template = '<div class="resultCopyWrapper"><p class="p result-name">' + resultName + '</p><p class="p result-location">' + resultLocation + '</p><p class="p full-result">' + result + '</p></div><img src="' + resultImg + '" alt="" class="result-img">';
        $('#result1').html(template);
        setTimeout(() => {
            placeInCenter("result1");
        }, 100);
    });

    $('.js-closeplayer').on('click', function(){
        $('.videoScreen, .quoteScreen, .resultScreen').removeClass('is-active');
        jwplayer('video1').remove();
    });

    $('select').on('change', function() {
        var tempLocation = (this.value).toLowerCase();
        switch (tempLocation) { 
            case "spanish":
            case "espanol":
                window.location = "/espanol/";
                break;
            default:
                window.location = "/";
        }
    });

    $('.js-load-columns').on('click', function(){
        var allHiddenColumns = $('.hidden-column');
        try {
            if (allHiddenColumns.length > 0) {
                for (let index = 0; index < 2; index++) {
                    allHiddenColumns[index].classList.remove('hidden-column');
                }
            }
        } catch(e) {
            $(this).remove();
        }
        if (allHiddenColumns.length < 3) {
            $(this).remove();
        }
    });


//*------------------------------------*\
//      Functions
//*------------------------------------*/

    function scrolled() {
        if (isInView($('.graph-container1'))) {
            $('.graph-container1').addClass('is-active');
            if (!$('.js-graph1').hasClass('is-active')) {
                $('.js-graph1').addClass('is-active');
                increaseNumber($('.js-graph1').text(),1);
            }
        }
        if (isInView($('.graph-container2'))) {
            $('.graph-container2').addClass('is-active');
            if (!$('.js-graph2').hasClass('is-active')) {
                $('.js-graph2').addClass('is-active');
                increaseNumber($('.js-graph2').text(),2);
            }
        }
        if (isInView($('.graph-container3'))) {
            $('.graph-container3').addClass('is-active');
            if (!$('.js-graph3').hasClass('is-active')) {
                $('.js-graph3').addClass('is-active');
                increaseNumber($('.js-graph3').text(),3);
            }
        }
        if (isInView($('.graph-container4'))) {
            $('.graph-container4').addClass('is-active');
            if (!$('.js-graph4').hasClass('is-active')) {
                $('.js-graph4').addClass('is-active');
                increaseNumber($('.js-graph4').text(),4);
            }
        }
    }

    function fillCarousel() {
        var cardCounter = 0,
        columnTemplate = '<div class="column"></div>',

        // how many columns are needed
        numColumns = Math.ceil(carouselSize/3);
        
        // loop num columns
        for (let tempcounter = 0; tempcounter < numColumns; tempcounter++) {
            if (tempcounter > 1) columnTemplate = '<div class="column hidden-column"></div>';
            $('.slide-wrapper').append(columnTemplate);
            // fill column loop up to 3 times each column
            for (let index = 0; index < 3; index++) {
                if (cardCounter < carouselSize) {
                    // fill this card data
                    createCard(carouselObj[cardCounter]);
                    cardCounter++;
                }
            }
        }
    }

    function createCard(singleCard) {
        var cardTemplate = '';
        try {
            switch (singleCard.type) { 
                case "video":
                    var tempImg = singleCard.image || 'https://content.jwplatform.com/thumbs/' + singleCard.mediaid + '-320.jpg';
                    cardTemplate = '<div class="card video js-play-video" data-mediaid="' + singleCard.mediaid + '" data-image="' + tempImg + '"></div>';
                    break;
                case "result":
                    cardTemplate = '<div class="card js-result-card result-wrapper"><img src="' + singleCard.image + '" alt="" class="result-img"><p class="p result-name" data-name="' + singleCard.name + '">' + singleCard.name + '</p><p class="p result-location">' + singleCard.location + '</p><p class="p full-result">' + singleCard.testimony + '</p></div>';
                    break;
                default: // quote
                    cardTemplate = '<div class="card js-quote-card quote-wrapper"><div class="card-copy"><p class="p quote-title">"' + singleCard.title + '"</p><p class="p full-quote">"' + singleCard.testimony + '"</p><p class="p quote-name" data-name="' + singleCard.name + '">' + singleCard.name + ', ' + singleCard.location + '</p></div></div>';
            }
            // add card to last column
            $('.slide-wrapper').find(".column")[$('.slide-wrapper').find(".column").length-1].innerHTML += cardTemplate;
        } catch (e){
            console.log(e);
        }
    }

    function moveCarousel(element) {
        var tempdirection = element.data('dir'),
        leftValue = Math.ceil($('.slide-wrapper').offset().left - $('.s2-container').offset().left),
        containerOffset = 926,
        availMovement = caroWidth - containerOffset + fadeOffset,
        tempNewLeft = 0;
        $('.js-carousel__nav').addClass('is-active');
        if (tempdirection === "left" && leftValue < fadeOffset) {
            // get left pos and ADD 277 * 3 = 831 to it
            tempNewLeft = leftValue + (cardWidth * rowsToMove);
            // if new left is more than 95 then set left pos to 95 (farthest left)
            if (tempNewLeft >= fadeOffset) {
                tempNewLeft = fadeOffset;
                $('.carousel__nav--left').removeClass('is-active');
            }
            // then put in new left value
            $('.slide-wrapper').css("left",tempNewLeft + "px");
        } else if (tempdirection === "right" && leftValue > (fadeOffset - availMovement)) {
            // pet left pos and SUBTRACT 277 * 3 = 831 to it
            tempNewLeft = leftValue - (cardWidth * rowsToMove);
            // if new left is less than farthest right point then set to farthest right point
            if (tempNewLeft <= fadeOffset-availMovement) {
                tempNewLeft = fadeOffset-availMovement;
                $('.carousel__nav--right').removeClass('is-active');
            }
            // then put in new left value
            $('.slide-wrapper').css("left",tempNewLeft + "px");
        }

        // width of window - 1021
    }

    function changeName() {
        if (counter > (shuffledNames.length - 1)) {counter = 0;}
        $('.js-span-placeholder').prepend(justasktemplate + shuffledNames[counter] + '</span>');
        setTimeout(() => {
            $('.js-justask.is-active').addClass('moveOut').removeClass('is-active');
            $('.js-span-placeholder').find('.moveIn').addClass('is-active');
        }, 1000);
        setTimeout(() => {
            $('.moveOut').remove();
            counter++;
            changeName();
        }, $nameChangeMS);
    }

    function calcUnitsSold() {
        var todaysdate = new moment(),
        datediff = todaysdate.diff(moment('08-01-2008 0:01 AM', 'MM-DD-YYYY hh:mm A')),
        unitsSold = (Math.ceil(datediff/4000) + increaseUnitsSold).toString();
        // format number
        unitsSold = unitsSold.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        // console.log('datediff',datediff);
        $('.js-unitsSold').hide().html(unitsSold).fadeIn();
        setTimeout(() => {
            calcUnitsSold();
        }, $unitsSoldMS);
    }
    
    function shuffle() {
        var array = ['DENNIS.', 'DALE.', 'SHIRLEY.', 'MARYJO.', 'KIM.', 'RHONDA.', 'JUDITH.', 'MICHELE.', 'CARRIE.', 'SANDY.', 'CINDY.', 'DANIEL.'];
        var currentIndex = array.length, temporaryValue, randomIndex;
        while (0 !== currentIndex) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
        return array;
    }

    function isInView(elem){
        return $(elem).offset().top - $(window).scrollTop() < (Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - $(elem).height()) ;
    }

    function increaseNumber(maxNum,graph){
        var tempmaxNum = parseInt(maxNum);
        var tempIdentifier = maxNum.replace(/[0-9]/g, '');
        var timeSplit = Math.round(2000 / tempmaxNum);
        var counter = 0;

        function timer() {
            if (counter < tempmaxNum + 1) {
                $('.js-graph' + graph).html("" + counter + tempIdentifier);
                counter++;
                setTimeout(() => timer(), timeSplit);
            }
        }
        timer();
    }

    function setVideoImages() {
        var allVideoCards = $('.video');
        allVideoCards.each(function(){
            var template = '<span class="video-background" style="background-image:url(' + $(this).data('image') + ')"></span>';
            $(this).prepend(template);
        });
    }

    function placeInCenter(idName) {
        centerTimer++;
        var viewportheight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
        elementheight = $('#' + idName).height(),
        scrollPosition = window.pageYOffset,
        videoTopPos = Math.round(((viewportheight - elementheight) / 2) + scrollPosition);
        if (elementheight == 0) {
            setTimeout(() => {
                if (centerTimer < 50) placeInCenter(idName);
            }, 100);
        } else {
            if (videoTopPos < scrollPosition) videoTopPos = scrollPosition; 
            $('#' + idName).css("top", videoTopPos + 'px').fadeIn();
            // position close button
            $('.xcloseplayer').css('top', (videoTopPos + 10) + 'px');

            if ($('#' + idName).css('top') == (videoTopPos+"px")) {
                $('#' + idName).addClass('is-active');
            } else {
                setTimeout(() => {
                    if (centerTimer < 50) placeInCenter(idName);
                }, 100);
            }
        }
    }
});








